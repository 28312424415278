import { useNavigate, useLocation } from 'react-router-dom';

import { useState, useEffect } from 'react';
import powerd from '../../assets/logos/powerd.svg';
import logo_light from '../../assets/logos/logo-chat-light.svg';
import logo_dark from '../../assets/logos/logo-chat-dark.svg';
const DoctorFooter = () => {
  const navigate = useNavigate();

  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);
  const goChat = () => {
    navigate('/post-chat?UserId=39');
  };
  return (
    <footer
      className='dark:bg-[#110130] font-arabic pt-6 pb-1 flex flex-row justify-center items-center'
      dir='rtl'>
      <div className='flex items-center gap-2'>
      
        <img
          src={theme === 'light' ? logo_light : logo_dark}
          onClick={toggleTheme}
          alt='Mena Logo'
          className='h-[20px] w-[60px] pb-1 cursor-pointer'
        />
          <p className='text-[#4c00ff] text-sm dark:text-white'>Powered by</p>
      </div>
      <div className='absolute bottom-0 left-0 right-0 h-1 bg-[#5d1dd0]'></div>
    </footer>
  );
};

export default DoctorFooter;
