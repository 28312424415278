import { useState } from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { AiOutlineLoading } from 'react-icons/ai'; // Spinner icon
import axios from '../../utilities/axios';

const ImageGallery = ({ images }) => {
    const [previewImage, setPreviewImage] = useState(null);
    const [likedImages, setLikedImages] = useState({});
    const [loadingImages, setLoadingImages] = useState({}); // Track loading state for each image

    const isMasonryLayout = images.length === 6;

    const openPreview = (image) => {
        setPreviewImage(image);
    };

    const closePreview = () => {
        setPreviewImage(null);
    };

    const toggleLike = async (image) => {
        const imageId = image.id; // Assuming `image` has an `id` field.
        const isCurrentlyLiked = likedImages[imageId];

        // Set loading state for the image
        setLoadingImages((prev) => ({
            ...prev,
            [imageId]: true,
        }));

        try {
            // Call the appropriate API endpoint based on the current like status
            const endpoint = isCurrentlyLiked
                ? `/sliders/disLike/${imageId}`
                : `/sliders/like/${imageId}`;

            const response = await axios.get(endpoint);

            if (response.data) {
                // Toggle the like state if the API call was successful
                setLikedImages((prev) => ({
                    ...prev,
                    [imageId]: !isCurrentlyLiked,
                }));
            } else {
                console.error("Failed to toggle like/dislike:", response.statusText);
            }
        } catch (error) {
            console.error("Error toggling like/dislike:", error);
        } finally {
            // Clear the loading state for the image
            setLoadingImages((prev) => ({
                ...prev,
                [imageId]: false,
            }));
        }
    };

    return (
        <>
            {/* Image Preview Modal */}
            {previewImage && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 rounded-md"
                    onClick={closePreview}
                >
                    <div
                        className="relative max-w-4xl w-full p-4 "
                        onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing it
                    >
                        <img
                            src={previewImage.image}
                            alt="Preview"
                            className="w-full rounded-xl shadow object-fill max-h-[90vh] left-4 right-4"
                        />
                        {/* Like Button */}
                        <button
                            onClick={() => toggleLike(previewImage)}
                            className={`absolute top-8 right-10 w-10 h-10 flex justify-center items-center rounded-full shadow-lg transition-colors ${likedImages[previewImage?.id] ? 'bg-white text-[#4c00ff]' : 'bg-white text-[#4c00ff]'
                                }`}
                        >
                            {loadingImages[previewImage?.id] ? (
                                <AiOutlineLoading className="animate-spin" />
                            ) : likedImages[previewImage?.id] ? (
                                <FaHeart />
                            ) : (
                                <FaRegHeart />
                            )}
                        </button>
                        <button
                            onClick={() => closePreview()}
                            className="absolute top-8 left-10 w-10 h-10 flex justify-center items-center rounded-full shadow-lg  bg-white
                                "
                        >
                      x
                        </button>
                        {/* Description at the bottom */}
                        {previewImage.description && (<div className="absolute bottom-4 left-4 right-4 p-4 bg-black bg-opacity-75 text-white ">
                            <p className="text-center">{previewImage.description}</p>
                        </div>)
                        }
                    </div>
                </div>
            )}


            {isMasonryLayout ? (
                <div className="grid grid-cols-2 gap-2 px-4">
                    <div>
                        <img
                            className="w-full rounded-xl shadow h-[414px] object-cover cursor-pointer"
                            src={images[0].image}
                            alt="Image 01"
                            onClick={() => openPreview(images[0])}
                        />
                    </div>
                    <div className="space-y-2">
                        <img
                            className="w-full rounded-xl shadow h-[203px] object-cover cursor-pointer"
                            src={images[1].image}
                            alt="Image 02"
                            onClick={() => openPreview(images[1])}
                        />
                        <img
                            className="w-full rounded-xl shadow h-[203px] object-cover cursor-pointer"
                            src={images[2].image}
                            alt="Image 03"
                            onClick={() => openPreview(images[2])}
                        />
                    </div>
                    <div className="space-y-2">
                        <img
                            className="w-full rounded-xl shadow object-cover h-[203px] cursor-pointer"
                            src={images[4].image}
                            alt="Image 05"
                            onClick={() => openPreview(images[4])}
                        />
                        <img
                            className="w-full rounded-xl shadow object-cover h-[203px] cursor-pointer"
                            src={images[5].image}
                            alt="Image 06"
                            onClick={() => openPreview(images[5])}
                        />
                    </div>
                    <div>
                        <img
                            className="w-full rounded-xl shadow object-cover h-[414px] cursor-pointer"
                            src={images[3].image}
                            alt="Image 04"
                            onClick={() => openPreview(images[3])}
                        />
                    </div>
                </div>
            )
                : (
                    <div className="grid grid-cols-3 gap-4 px-4">
                        {images.map((image) => (
                            <div key={image.id}>
                                <img
                                    className="w-full h-[200px] rounded-xl shadow object-cover cursor-pointer"
                                    src={image.image}
                                    alt={`Image ${image.id}`}
                                    onClick={() => openPreview(image)}
                                />

                            </div>
                        ))}
                    </div>
                )}
        </>
    );
};

export default ImageGallery;
