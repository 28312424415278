import { useState, useEffect, useRef } from 'react';
import {

  ClockIcon,
  MapPinIcon,
  PhoneIcon,
} from '@heroicons/react/24/solid';
import doctor from '../../assets/doctor/doctor.jpg';
import star from '../../assets/doctor/star.svg';
import halfStar from '../../assets/doctor/halfStar.svg';
import greyStar from '../../assets/doctor/grey_star.svg';
import bag from '../../assets/doctor/bag.svg';
import drug from '../../assets/doctor/drug.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useParams } from 'react-router-dom';
import axios from '../../utilities/axios';
import useRequireAuth from '../../Hooks/useRequireAuth';
import Book from '../../components/Modals/Book';
import profile_image from '../../assets/icons/profile.png';
import moment from 'moment';
import 'moment/locale/ar';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Maps from '../../components/Map/Maps';
import DoctorFooter from '../../components/Footer/DoctorFooter';
import ImageGallery from '../../components/Doctor/DoctorGallery';
import ActionButtons from '../../components/Doctor/ReservationButton';
const User = () => {

  const analytics = getAnalytics(); // Initialize Firebase Analytics
  const [showModal, setShowModal] = useState(false);
  const [theme, setTheme] = useState('light');
  const [loading, setLoading] = useState(false);
  const commentInputRef = useRef(null);
  const [newComment, setNewComment] = useState('');
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [profile, setProfile] = useState({});
  const [images, setImages] = useState([]);
  const [reviews, setReviews] = useState([]);

  const { id: paramId } = useParams(); // Renaming to avoid confusion
  const [id, setId] = useState(paramId); // State for ID


  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`/users/profile?id=${id}`)
        .then((response) => {
          const newId = response.data.id; // Get new ID
          setId(newId); // Update ID state
          setProfile(response.data);
          console.log({ profile });
          fetchImages();
          fetchReviews();
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [id]);
  const fetchImages = async () => {
    try {
      const response = await axios.get(`/sliders?UserId=${id}`);

      setImages(response.data);
      console.log(response.data, { images });
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  const fetchReviews = async () => {
    try {
      const response = await axios.post(`/users/reviews`, {
        page: 1,
        size: 10,
        id: id,
      });

      setReviews(response.data);
      console.log(response.data, { reviews });
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };
  const getTimeLabel = (time) => {
    moment.locale('en');

    let formattedTime = moment(time, moment.ISO_8601, true);

    if (!formattedTime.isValid()) {
      formattedTime = moment(new Date(time));
    }

    if (!formattedTime.isValid()) {
      return 'Invalid date';
    }

    const now = moment();

    if (formattedTime.isSame(now, 'minute')) {
      return 'Just now';
    } else if (
      formattedTime.isSame(now, 'hour') ||
      formattedTime.isSame(now, 'day') ||
      formattedTime.isAfter(now)
    ) {
      return formattedTime.fromNow();
    } else {
      return formattedTime.fromNow();
    }
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };
  const addComment = () => {
    if (!newComment.trim()) return;

    axios
      .post('https://www.mena4health.com/apis/users/reviews/create', {
        text: newComment,
        userId: id,
        name: 'مجهول',
      })
      .then((response) => {
        console.log('Comment added successfully:', response.data);
        setNewComment('');
        fetchReviews();
      })
      .catch((error) => {
        console.error('Error adding comment:', error);
      });
  };

  return (
    <div className={`font-arabic ${theme === 'dark' ? 'dark' : ''} relative`}>
      {loading ? (
        <div className='h-[80vh]  inset-0 flex items-center justify-center bg-[#F6F2EF] dark:bg-[#110130] bg-opacity-50'>
          <div
            className={`${theme === 'dark' ? 'user_loader_dark' : 'user_loader_light'
              }`}></div>
        </div>
      ) : (
        <>
          <div className='bg-[#F6F2EF] dark:bg-[#110130] rtl'>
            <div className='relative w-full h-[400px]'>
              {' '}
              <div className='relative w-full h-[400px]'>
                <div className='relative w-full h-full bg-gray-300 overflow-hidden'>
                  {/* <div className='absolute inset-0 bg-gray-400 animate-slide bg-right'></div> */}
                  <img
                    src={profile.mainImage || doctor}
                    alt='doctor'
                    className='absolute inset-0 w-full h-full object-cover opacity-0 animate-fadeIn delay-150'
                  />

                </div>
              </div>
            </div>

            <div
              style={{ marginTop: '-40px' }}
              className='relative bg-[#F6F2EF] dark:bg-[#110130]  rounded-t-[30px]  dark:shadow-top-only-dark'>
              <div
                className='p-5 !pb-0 md:p-8 bg-[#F6F2EF] rounded-t-[30px] dark:bg-[#110130]'
                dir='rtl'>
                <div className='w-full flex items-center justify-between mt-4  animate-fadeIn'>
                  <h2 className='text-[22px] md:text-2xl font-extrabold leading-5 text-[#110130] dark:text-[#4C00FF]'>
                    {profile.name || ' '}
                  </h2>

                  <div className='flex space-x-1'>
                    {Array.from({ length: 5 }, (_, index) => {
                      const starValue = index + 1;
                      if (profile.rate >= starValue) {
                        return (
                          <img
                            key={index}
                            src={star}
                            alt='star'
                            className='h-5 w-5'
                          />
                        );
                      } else if (profile.rate >= starValue - 0.5) {
                        return (
                          <img
                            key={index}
                            src={halfStar}
                            alt='half star'
                            className='h-5 w-5'
                          />
                        );
                      } else {
                        return (
                          <img
                            key={index}
                            src={greyStar}
                            alt='grey star'
                            className='h-5 w-5'
                          />
                        );
                      }
                    })}
                  </div>
                </div>
                <hr className=' border-dotted border-t-2 border-gray-400 my-8 dark:border-[#F6F2EF]' />

                <div className='flex justify-around items-center '>
                  <div className='text-center animate-slideIn'>
                    <div className='bg-[#4C00FF1A] rounded-full p-3 inline-block'>
                      <img src={bag} alt='icon' className='h-8 w-8 mx-auto' />
                    </div>
                    <p className='text-[15px] text-[#110130] dark:text-white'>
                      <span className='font-bold'>
                        {' '}
                        {profile.experience || 1}+
                      </span>{' '}
                      سنة خبرة
                    </p>
                  </div>
                  <div className='text-center animate-slideIn delay-100'>
                    <div className='bg-[#34C7591A] rounded-full p-3 inline-block'>
                      <img src={drug} alt='icon' className='h-9 w-9 mx-auto' />
                    </div>
                    <p className='text-[15px] text-[#110130] dark:text-white'>
                      <span className='font-bold'>
                        {profile.reservationNumbers || 0}+
                      </span>{' '}
                      حجز
                    </p>
                  </div>
                  <div className='text-center animate-slideIn delay-200'>
                    <div className='bg-[#FFCC001A] rounded-full p-3 inline-block'>
                      <img src={star} alt='icon' className='h-8 w-8 mx-auto' />
                    </div>
                    <p className='text-[15px] text-[#110130] dark:text-white'>
                      <span className='font-bold'>100+</span> تقييم
                    </p>
                  </div>
                </div>
                <hr className=' border-dotted border-t-2 border-gray-400 mt-8 mb-5 dark:border-[#F6F2EF]' />

                <p className='text-lg text-[#110130] dark:text-white animate-fadeInDown leading-12'>
                  {profile.bio || ''}
                </p>

                <hr className=' border-dotted border-t-2 border-gray-400 my-5  dark:border-[#F6F2EF]' />

                <h3 className='text-lg font-bold text-[#281eb3] dark:text-[#F6F2EF]'>
                  معلومات العيادة
                </h3>

                <div className='mt-4 space-y-3'>
                  <div className='flex items-center'>
                    <div className='bg-[#4C00FF14] dark:bg-[#4C00FF14] rounded-[8px] p-2 ml-2'>
                      <ClockIcon className='h-4 w-4 md:h-6 md:w-6 text-[#4C00FF] dark:text-[#4C00FF]' />
                    </div>
                    <p className='text-[#110130] text-sm md:text-base dark:text-[#F6F2EF]'>
                      {profile.workHours ||
                        'يفتح يومياً من الساعة 08:00 صباحاً إلى الساعة 08:00 مساءً.'}
                    </p>
                  </div>
                  <div className='flex items-center'>
                    <div className='bg-[#4C00FF14] dark:bg-[#4C00FF14] bg-opacity-20 rounded-[8px] p-2 ml-2'>
                      <MapPinIcon className='h-4 w-4 md:h-6 md:w-6 text-[#4C00FF] dark:text-[#4C00FF]' />
                    </div>
                    <p className='text-[#110130] text-sm md:text-base dark:text-[#F6F2EF]'>
                      {profile.address || 'سوريا - حلب - شارع سعد الله الجابري'}
                    </p>
                  </div>
                  <div className='flex items-center'>
                    <div className='bg-[#4C00FF14] dark:bg-[#4C00FF14] bg-opacity-20 rounded-[8px] p-2 ml-2'>
                      <PhoneIcon className='h-4 w-4 md:h-6 md:w-6 text-[#4C00FF] dark:text-[#4C00FF]' />
                    </div>
                    <p
                      onClick={() => {
                        logEvent(analytics, 'doctor_call');
                        let phoneNumber = profile.mobile || '';
                        if (phoneNumber && !phoneNumber.startsWith('0')) {
                          phoneNumber = `0${phoneNumber}`;
                        }
                        window.location.href = `tel:${phoneNumber}`;
                      }}
                      className='text-[#110130] hover:text-[#4c00ff] cursor-pointer text-sm md:text-base dark:hover:text-[#4c00ff] dark:text-[#F6F2EF]'>
                      {profile.mobile && !profile.mobile.startsWith('0')
                        ? `0${profile.mobile}`
                        : profile.mobile || ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr className='px-3 border-dotted border-t-2 border-gray-400 my-5 dark:border-[#F6F2EF]' /> */}

            {/* <div className='flex items-center justify-center rounded-lg'>
              <Maps
                lat={profile.lat || 36.386558}
                lon={profile.lon || 43.179497}
              />
            </div> */}
            <hr className=' border-dotted border-t-2 border-gray-400 my-8 dark:border-[#F6F2EF]' />
            <ImageGallery images={images} />


            <hr className=' border-dotted border-t-2 border-gray-400 my-8 dark:border-[#F6F2EF]' />

            <div className=' px-6' dir='rtl'>
              <h3 className='text-lg font-bold text-[#281eb3] mb-3 dark:text-[#F6F2EF]'>
                تقييمات المراجعين {' '}
              </h3>

              <div className='space-y-6 mb-4'>
                {reviews.map((review, index) => {
                  const starCount = Math.floor(Math.random() * 2) + 4;

                  return (
                    <div
                      key={index}
                      className='bg-[#F6F2EF] dark:bg-[#110130] rounded-lg p-4 shadow-md'>
                      <div className='flex items-center justify-between mb-4'>
                        <div className='flex items-center'>
                          <img
                            src={profile_image}
                            alt='reviewer'
                            className='h-12 w-12 rounded-full ml-4'
                          />
                          <div>
                            <h4 className='font-bold text-[#110130] dark:text-white'>
                              {review.name || ' مجهول '}
                            </h4>
                            <span className='text-sm text-gray-500'>
                              {getTimeLabel(review.createdAt) || ' مجهول '}
                            </span>
                          </div>
                        </div>

                        <div className='flex items-center space-x-reverse space-x-1'>
                          {/* Render stars based on the random star count */}
                          {[...Array(starCount)].map((_, i) => (
                            <img
                              key={i}
                              src={star}
                              alt='star'
                              className='h-4 w-4'
                            />
                          ))}
                          {[...Array(5 - starCount)].map((_, i) => (
                            <img
                              key={i}
                              src={greyStar}
                              alt='grey star'
                              className='h-4 w-4'
                            />
                          ))}
                        </div>
                      </div>

                      <div className='mt-2'>
                        <p className='text-sm text-[#110130] dark:text-white'>
                          {review.text || ' '}
                        </p>
                      </div>
                    </div>
                  );
                })}

                <div
                  className={`${theme === 'dark'
                    ? 'custom-border-dark'
                    : 'custom-border-light'
                    } mt-7  flex items-center py-4 relative`}>
                  <input
                    type='text'
                    placeholder='قم بإضافة مراجعتك...'
                    className='flex-grow bg-transparent border-none focus:outline-none text-[#110130] dark:text-white text-sm md:text-base'
                    ref={commentInputRef}
                    dir='rtl'
                    value={newComment}
                    onChange={handleCommentChange}
                  />
                  <button
                    className='text-[#4C00FF] dark:text-[#4C00FF] ml-4 text-sm md:text-base'
                    onClick={addComment}>
                    نشر
                  </button>
                </div>
              </div>


             <ActionButtons profile={profile}/>
        

            </div>
          </div>
          <Book userId={id} showModal={showModal} setShowModal={setShowModal} />
        </>
      )}
      <DoctorFooter/>
    </div>
  );
};

export default User;
